import React, { useCallback, useState } from 'react';
import { Field, FieldProps, useField } from 'formik';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    container,
    inputBox,
    input,
    label,
    labelError,
    plus,
    imgGrid,
    img,
    deleteFile,
    fileContent,
    fileItem,
} from './file-input.module.scss';

import { FileErrorEnum, IBase64File, IValidateFileOptions } from '../../utils/file-utils';
import getTranslationKey from '../../utils/get-translation-key';

import BaseFileInput, { IUploadResult } from './base-file-input';

import AddIcon from '../../assets/images/svg/add.svg';
import AttachmentIcon from '../../assets/images/svg/attachment.svg';
import ReceiptIcon from '../../assets/images/svg/receipt.svg';
import LoaderIcon from '../../assets/images/svg/loader.svg';
import RemoveIcon from '../../assets/images/svg/remove.svg';
import FilePreview from './file-preview';

interface IFileInputProps extends IValidateFileOptions {
    className?: string;
    name: string;
    placeholder?: string;
    identifier?: string | number;
    errorStyle?: boolean;
    type?: 'short' | 'long' | 'receipt';
}

const FileInput: React.FC<IFileInputProps> = ({
    className = '',
    name,
    placeholder,
    fileTypes,
    maxFileSize = 5120000,
    maxFileCount = 1,
    errorStyle = false,
    type = 'long',
}) => {
    const { t } = useI18next();
    const [isFileLoading, setIsFileLoading] = useState<boolean>(false);
    const [remainingCount, setRemainingCount] = useState(maxFileCount);
    const errorMessages = createErrorMessages(t);
    const [field, , helpers] = useField(name);

    const handleChange = useCallback(
        (fieldProps: FieldProps) =>
            ({ uploadedFiles, uploadErrors }: IUploadResult) => {
                const { form, field } = fieldProps;
                setRemainingCount(
                    maxFileCount -
                        (uploadedFiles ? uploadedFiles : []).length -
                        (form.values[field.name] ? form.values[field.name] : []).length
                );
                if (uploadErrors && uploadErrors.length > 0) {
                    uploadErrors.forEach((error) => form.setFieldError(field.name, error));
                } else {
                    form.setFieldValue(field.name, [
                        ...(form.values[field.name] ? form.values[field.name] : []),
                        ...(uploadedFiles ? uploadedFiles : []),
                    ]);
                }
            },
        []
    );

    const handleDeleteFile = (toDeleteIndex: number) => {
        const newValue = field.value.filter((_item: any, index: number) => index !== toDeleteIndex);
        if (newValue.length > 0) helpers.setValue(newValue);
        else helpers.setValue(null);
        setRemainingCount(maxFileCount - newValue.length);
    };

    return (
        <div className={`${container} ${className}`}>
            <div className={inputBox}>
                <Field name={name}>
                    {(fieldProps: FieldProps<IBase64File[]>) => (
                        <>
                            <BaseFileInput
                                labelClassName={`${label} ${errorStyle ? labelError : ''}`}
                                inputClassName={input}
                                name={name}
                                fileTypes={fileTypes}
                                maxFileCount={remainingCount}
                                maxFileSize={maxFileSize}
                                onUploadStart={() => setIsFileLoading(true)}
                                onUploadFinish={() => setIsFileLoading(false)}
                                onChange={handleChange(fieldProps)}
                                onBlur={fieldProps.field.onBlur}
                                errorMessages={errorMessages}
                            >
                                <span>{placeholder}</span>
                                {type === 'short' && <AttachmentIcon className={plus} />}
                                {type === 'long' && <AddIcon className={plus} />}
                                {type === 'receipt' && <ReceiptIcon className={plus} />}
                            </BaseFileInput>
                            <div className={imgGrid}>
                                {fieldProps.field.value &&
                                    fieldProps.field.value.map((file, index) => {
                                        if (typeof file.content !== 'string') return false;
                                        return (
                                            <div
                                                key={`uploaded_image_${index}`}
                                                className={fileItem}
                                            >
                                                <FilePreview file={file} />
                                                <button
                                                    type="button"
                                                    className={deleteFile}
                                                    onClick={() => handleDeleteFile(index)}
                                                >
                                                    <RemoveIcon />
                                                </button>
                                            </div>
                                        );
                                    })}
                                {isFileLoading && (
                                    <div className={fileItem}>
                                        <div className={`${fileContent}`}>
                                            <LoaderIcon className={img} />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </Field>
            </div>
        </div>
    );
};

export const createErrorMessages = (t: ReturnType<typeof useI18next>['t']) => {
    const translations = getTranslationKey('form.file');
    return {
        [FileErrorEnum.OnLoad]: t(translations(FileErrorEnum.OnLoad)),
        [FileErrorEnum.TooMuchFiles]: t(translations(FileErrorEnum.TooMuchFiles)),
        [FileErrorEnum.FileNotAllowed]: t(translations(FileErrorEnum.FileNotAllowed)),
        [FileErrorEnum.TooBig]: t(translations(FileErrorEnum.TooBig)),
    };
};

export default FileInput;
