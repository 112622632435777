// extracted by mini-css-extract-plugin
export var backgroundAnimation = "file-input-module--backgroundAnimation--64e82";
export var container = "file-input-module--container--64e0f";
export var deleteFile = "file-input-module--delete-file--f3f77";
export var errorBlink = "file-input-module--error-blink--82e4a";
export var fileContent = "file-input-module--file-content--725b0";
export var fileItem = "file-input-module--file-item--cd27f";
export var img = "file-input-module--img--55bfb";
export var imgGrid = "file-input-module--img-grid--e0d03";
export var input = "file-input-module--input--f21f6";
export var inputBox = "file-input-module--input-box--2e344";
export var label = "file-input-module--label--cb8d7";
export var labelError = "file-input-module--label-error--0c480";
export var plus = "file-input-module--plus--42dcd";